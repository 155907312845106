import React, { FC } from 'react'
import dynamic from 'next/dynamic'
const DynamicComponentWithNoSSR = dynamic(
  () => import('../components/main'),
  { ssr: false }
)
const Home: FC = () => (
  <DynamicComponentWithNoSSR />
)

export default Home
